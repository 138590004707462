@import "../../../../../../assets/styles/_variables";

.root {
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: -140px;
  min-height: 280px;
  min-width:100%;
  z-index: 1;

  :global(.card){
    @for $i from 2 through 18 {
      &:nth-child(#{$i}) {
        margin-left: -150px;
      }
    }
    & > img {
      width: 100%;
    }
  }

  transform: translateY(300px);
  transition: all 0.5s ease-in-out;
  &.dealt{
    transform: translateY(0px);
  }
}

.abilityActive{
  z-index: 100;
}
