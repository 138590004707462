.root{
  background: #bb6d2a;
  border-radius: 4px;
  padding: 7px 14px;
  outline: none;
  border: none;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  &:disabled{
    background: #434A57;
  }
}