.root {
  color: #ffffff;
  margin-bottom: 10px;
  display:flex;
  flex-direction: column;
  b,
  p {
    margin: 0;
  }

}
