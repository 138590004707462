.root {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top:25px;
  & > div{
    width: 220px;
    height: 308px;
    filter: drop-shadow(0px -3.77318px 3.77318px rgba(0, 0, 0, 0.25));
    @for $i from 2 through 13 {
      &:nth-child(#{$i}) {
        margin-top: -262px;
      }
    }

  }

}
