.root{
  width: 200px;
  height: 280px;
  border: 5px solid transparent;
  border-radius: 12px;
  overflow: hidden;

  display:flex;
  align-items: center;
  justify-content:center;
  color:#fff;
  font-family: "Harbour";
  font-style: normal;
  font-weight: 400;
  &:hover{
    cursor: pointer;
  }
  &.selected{
    border-color: #FFFFFF;
  }
  img, object{
    width: 200px;
    height: 280px;
  }
}