@import "assets/styles/_variables";
@import "assets/styles/_global";

html,
body {
  margin: 0;
  background-color: $background-color;
}
#root{
  height: 100vh;
}
