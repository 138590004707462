.root{
  margin: 6vh auto 6vh;
  max-width: 1100px;
  background: #0A0F17;
  border: 1px solid #2F3D54;
  border-radius: 5px;
  padding: 32px 28px 80px 28px;
  display: flex;
  align-items: center;
  justify-content:space-between;
  flex-direction: column;
  position:relative;
  & > img{
    position:absolute;
    bottom:0;
    z-index: 10;
    &:first-of-type{
      left: -13px;
    }
    &:last-child{
      right: -12px;
    }
  }
}
.title{
  font-family: 'Harbour';
  font-size: 63px;
  color: #BB6D2A;
}
.description{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  max-width: 580px;
  margin: 0 auto 30px;
  text-align: center;
}
.images, .actions{
  display:flex;
  align-items: center;
  justify-content:space-between;
}
.actions{
  button{
    &:first-child{
      margin-right: 20px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  margin-bottom: 60px;
}
.rightBlock{
  img{
    position: relative;
    &:nth-child(1){
      right: -16%;
      z-index: 1;
      transform: scale(1) translate(0, 21px);
  }
    &:nth-child(2){
      z-index: 1;
      transform: scale(1) translate(0, 1px);
    }
    &:nth-child(3){
      position: absolute;
      transform: scale(1) translate(171px, -52px);
    }
    &:nth-child(4){
      position: absolute;
      transform:scale(0.8) rotate(13.15deg) translate(329px, -137px)
    }
  }
}

.slider{
  width:100%;
}

.specialTitle{
  font-family: 'Harbour';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: #EEEEEE;
}
.specialDesc{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #929EAD;
  max-width: 460px;
  margin-bottom: 20px;
}
.rulesLink{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #BB6D2A;
  padding: 6px 12px;
  text-decoration: none;
  border-radius:10px;
  border: 1px solid #BB6D2A;
  margin-top: 40px;

}
