@import "src/assets/styles/_variables";
.root {
  border-collapse: collapse;
  margin-bottom: 17px;
  border-bottom: 1px solid #000;
  &:after {
    content: "Score";
    position: absolute;
    top: -27px;
    right: 0;
    color: #ffffff;
    background-color: $active-text-color;
    width: 220px;
    z-index: 3;
    padding: 4px 4px 4px 8px;
    border-radius: 4px 0px 0px 0px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }
  td {
    color: $active-text-color;
    border-right: 1px solid #141d2d;
    border-right: 1px solid #141d2d;
    min-width: 24px;
    text-align: center;
    &:last-child {
      text-align: left;
      color: #ffffff;
      padding: 3px 16px 2px 14px;
    }
    &:last-child {
      padding-right: 60px;
    }
  }
}
