.root {
  display: flex;
  width: 100%;
  background-color: #3f4f6d;
  position: relative;
  border-top: 1px solid;
}
.tricks {
  padding: 24px 14px;
  color: #ffffff;
}
.rounds {
  width: 100%;
  border-left: 1px solid #141d2d;
  border-right: 1px solid #141d2d;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 27px;
    left: 71px;
    right: 0;
    width: calc(100vw - 72px);
    height: 1px;
    background-color: #141d2d;
    z-index: 1;
  }
  table {
    width: 100%;
    padding: 0;
    margin: 0;
    border-collapse: collapse;
    position: relative;
    z-index: 2;
    td {
      text-align: center;
      color: #ffffff;
    }
  }
}
