.root{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width:440px;
  margin: 0 auto;

}
.picture{
  height: 18px;
}
.description{
  display: flex;
  flex-direction: column;
  span{
    font-family: "Harbour";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #BB6D2A;
  }
  p{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #929EAD;
    text-align: center;
    margin: 4px 0 0 0;
  }
}
