.root{
  grid-area: sessions;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 24px auto 40px;
  max-width: 1100px;
  padding: 24px;
  border: solid 1px #2F3D54;
  border-radius: 5px;
  background: linear-gradient(360deg, rgba(0, 163, 255, 0.2) 0%, rgba(0, 209, 255, 0) 35.75%), #141D2D;
}
.empty{
  color: #929EAD;
  margin: auto;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  button{
    margin-top: 20px;
    border-radius: 4px;
    padding: 7px 45px;
  }
}
.sessionsList{
  display: flex;
  height: 100%;
  align-items: flex-start;
  flex-direction: column;
  background: #090F18;
  border: 1px solid #2F3D54;
  box-sizing: border-box;
  border-radius: 8px;
}