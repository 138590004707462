.root {
  display: flex;
  align-items: center;
  img {
    filter: invert(27%) sepia(9%) saturate(974%) hue-rotate(181deg)
      brightness(91%) contrast(86%);
    margin-right: 15px;
    &.active{
      filter: invert(34%) sepia(83%) saturate(619%) hue-rotate(213deg) brightness(110%) contrast(112%);
    }
  }
}
