.root {
  height: 230px;
  display: flex;
  img {
    width: 100%;
    border-radius: 10px;
    filter: drop-shadow(0px -3.77318px 3.77318px rgba(0, 0, 0, 0.25));
    position: absolute;
    bottom: inherit;

    &:nth-child(1) {
      transform: translateY(00px);
    }
    &:nth-child(2) {
      transform: translateY(15px);
    }
    &:nth-child(3) {
      transform: translateY(30px);
    }
    &:nth-child(4) {
      transform: translateY(45px);
    }
    &:nth-child(5) {
      transform: translateY(60px);
    }
  }
  button{
    z-index:99;
    margin: auto;
  }
}
:global(.opponent) {
  img {
    top: -50px;
    filter: drop-shadow(0px 3.77318px 3.77318px rgba(0, 0, 0, 0.25));
    &:nth-child(1) {
      transform: translateY(00px);
    }
    &:nth-child(2) {
      transform: translateY(-15px);
    }
    &:nth-child(3) {
      transform: translateY(-30px);
    }
    &:nth-child(4) {
      transform: translateY(-45px);
    }
    &:nth-child(5) {
      transform: translateY(-60px);
    }
  }
}
