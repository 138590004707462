.root{
  padding: 0 32px;
  color: #FFFFFF;
  border-bottom: solid 1px #2F3D54;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 15px;
  height: 85px;
  span{
    margin: auto 0;
  }
  button, span:last-child{
    margin-left: auto;
  }
  button{
    border-radius: 4px;
  }
}
.vs{
  margin: 0 10px;
}
.players{
  display: flex;
  align-items: center;
}