@import "../../assets/styles/_variables";

.root {
  border: 2px solid transparent;
  border-radius: 10px;
  filter: drop-shadow(0px 3.62158px 3.62158px rgba(0, 0, 0, 0.55));
  width: 200px;
  height: 280px;
  box-sizing: border-box;
  transform-origin: top left;
  //perspective: 1000px;


  &:hover {
    .controls{
      button {
        opacity: 1;
      }
    }
  }


  .controls{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display:flex;
    align-items: center;
    justify-content: center;
    row-gap: 10px;
    flex-direction: column;
    height: 100%;
    width: 100%;
    button {
      background: #7b61ff;
      border-radius: 8px;
      opacity: 0;
      width: 140px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      border: none;
      color: #ffffff;
      cursor: pointer;
      z-index: 2;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.55);
      transition: transform 0.25s ease-in-out;
    }
  }

  &.opponent {
    pointer-events: none;
  }
}
.inner{
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.front, .back{
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 10px;
}
.back{
  transform: rotateY(180deg);
}


