.root{
  cursor: pointer;
  input[type="checkbox"]{
    appearance:none;
    width:40px;
    height:16px;
    border:1px solid #bb6d2a;
    border-radius:5px;
    position:relative;
    display:inline-block;
    overflow:hidden;
    vertical-align:middle;
    transition: background 0.3s;
    box-sizing:border-box;

  }
  input[type="checkbox"]:after{
    content:'';
    position:absolute;
    top:1px;
    left:1px;
    width:10px;
    height:10px;
    background:#bb6d2a;
    border:1px solid #bb6d2a;
    border-radius:4px;
    transition: left 0.1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
  input[type="checkbox"]:checked{
    background: rgba(187, 109, 42, 0.25);
    border-color: rgba(187, 109, 42, 0.25);
  }
  input[type="checkbox"]:checked:after{
    left:25px;
    border-color:#bb6d2a;
    background:#bb6d2a;
  }


  input[type="checkbox"]:disabled{
    opacity:0.5;
  }
}

