.root{
  padding: 10px 0px;
  background: #141D2D;
  color: #FFFFFF;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 15px;
  button{
    border-radius: 4px;
    margin-left: auto;
    &:last-child{
      margin-left: 20px;
    }
  }
}
.title{
  font-family: 'Harbour';
}