.root{
  position: absolute;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-transform: uppercase;
  padding: 10px;
  border-radius: 100%;
  width: max-content;
  height: max-content;
  z-index: 9;
  &.win{
    background: #27AE60;
  }
  &.loss{
    background: #FF5757;
  }
  &.tie{
    background: #7B61FF;
  }
}
.inner{
  width: 130px;
  height: 130px;
  border-radius: 100%;
  border: 1px solid #FFFFFF;
  color:#FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 58px;
}