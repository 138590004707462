.root{
  max-width:660px;
  min-width: 660px;
  padding: 20px 0 0 0;
  background: #0D1118;
  border-radius: 10px;
  h3{
    text-align: center;
  }
}
.deckList{
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 10px;
  max-height:40vh;
  min-height: 35vh;
  overflow-y:scroll;
  padding: 0 20px;
  align-items: flex-start;
  &::-webkit-scrollbar {
    width: 0px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
}
.deckItem{
  width:200px;
  border: 5px solid transparent;
  border-radius:12px;
  overflow: hidden;
  &:hover{
    cursor: pointer;
  }
  &.selected{
    border-color: #FFFFFF;
  }
  img{
    width: 100%;
  }
}
.footer{
  padding: 23px 20px;
  display:flex;
  justify-content: flex-end;
  column-gap: 15px;
  background-color: #141d2d;
  border-top: 1px solid #2F3D54;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}