.root {
  height: 100vh;
  max-height: 100vh;
  overflow-y: hidden;
  display: grid;
  grid-template-areas:
    "header"
    "sessions";
  grid-template-columns: 1fr;
  grid-template-rows: max-content;
  width: 100%;
}
