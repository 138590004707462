.root{
  min-width: 85px;
  height: 65px;
  border: 1px solid #3F4F6D;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 30px;
  span{
    text-transform: capitalize;
    color: #FFFFFF;
  }

}
.picture{
  height: 18px;
  margin-bottom: 6px;
}
