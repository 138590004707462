.root {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  box-sizing: border-box;
  padding:8px 8px 8px 0;
  border: 1px solid #3f4f6d;
  border-radius: 8px;
  height: 100%;
}
.container{
  color:#FFFFFF;
  overflow-y: scroll;
  padding: 12px 20px 12px 14px;
  &::-webkit-scrollbar {
    width: 4px;
    background-color: #0B121D;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #7B61FF;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: inherit;
  }
}
