@import "src/assets/styles/_variables";

.root {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $active-text-color;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  color: #ffffff;
  margin: 5px auto;
  font-size:12px;
}
