@import '../fonts';
.text {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 17px;
  &.default {
    font-style: normal;
    font-weight: 600;
  }
  &.regular {
    font-style: normal;
    font-weight: 400;
  }
  &.small {
    font-size: 10px;
    line-height: 12px;
    font-weight: 400;
    &.bold {
      font-weight: 700;
    }
  }
  &.title {
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    &.bold {
      font-weight: 700;
    }
  }
  &.message {
    font-size: 12px;
    line-height: 19px;
    font-weight: 400;
    &.bold {
      font-weight: 700;
    }
  }
}

h1{
  font-family: 'Harbour';
  font-style: normal;
  font-weight: 400;
  color: #BB6D2A;
}
h2{
  font-family: 'Harbour';
  font-style: normal;
  font-weight: 400;
  color: #BB6D2A;
}
h3{
  font-family: 'Harbour';
  font-style: normal;
  font-weight: 400;
  color: #EEEEEE;
}
p{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #929EAD;
}

ul{
  li{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #929EAD;
    list-style: none;
    margin-bottom: 10px;
    &:before{
      content: "•";
      color: #BB6D2A;
      display: inline-block;
      width: 1em;
      margin-left: -1em
    }
  }
}

.enableBlur>* {
  filter: blur(2px);
}

.disableBlur {
  filter: blur(0);
}
.dealingForOwner{
  position:absolute;
  animation: dealingAnimation infinite;
  animation-duration: .35s;
}
.dealingForOpponent{
  position:absolute;
  animation: dealingForOpponentAnimation infinite;
  animation-duration: .35s;
}

.swiper-pagination {
  margin-top: 25px;
  position: relative !important;

  .swiper-pagination-bullet {
    background-color: #434A57;
    &.swiper-pagination-bullet-active {
      background-color: #BB6D2A;
    }
  }
}
@keyframes dealingAnimation {
  from {
    transform: translateY(5px);
  }

  to {
    transform: translateY(600px);
  }
}
@keyframes dealingForOpponentAnimation {
  from {
    transform: translateY(-5px);
  }

  to {
    transform: translateY(-600px);
  }
}

.disableAbilityTooltips{
  .ability-tooltip{
    display: none !important
  }
}

.card{
  &:hover {
    cursor: pointer;
    transition: transform 0.25s ease-in-out;
    transform: translateY(-205px);
    .controls{
      button {
        opacity: 1;
      }
    }
  }
  &.animated{
    :hover{
      .ability-tooltip{
        opacity: 1;
        transform: translateX(calc(100% + 10px));
      }
    }
    //add helper to disable animation for dropped card
    &.card-exit-active{
      transform: translateY(0);
      transition: transform 700ms ease-out;
    }
    &.card-exit{
      transform: translateY(100%);
      transition: transform 700ms ease-out;
    }
    &.card-enter{
      transform: translateY(100%);
      transition: transform 700ms ease-out;
    }
    &.card-enter-active {
      transform: translateY(0%);
      transition: transform 700ms ease-out;
    }
  }
}
.card-opponent-exit-active{
  transform: translateY(-100%);
  transition: transform 700ms ease-out;
}
.card-opponent-exit{
  transform: translateY(0%);
  transition: transform 700ms ease-out;
}
.card-opponent-enter{
  transform: translateY(0%);
  transition: transform 700ms ease-out;
}
.card-opponent-enter-active {
  transform: translateY(-100%);
  transition: transform 700ms ease-out;
}
