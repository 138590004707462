.root {
  height: 100vh;
  max-height: 100vh;
  overflow-y: hidden;
  display: grid;
  grid-template-areas:
    "header header header"
    "remaining-cards playground treasure";
  grid-template-columns: 250px 1fr 252px;
  grid-template-rows: max-content;
}
.resultBlur{
  filter: blur(1px)
}
