.root {
  grid-area: playground;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}
.abilityActiveBackDrop{
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width: 100%;
  height: 100%;
  background: rgba(11, 18, 29, 0.8);
  z-index: 99
}