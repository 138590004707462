.root{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  background: #090F18;
  border: 1px solid #2F3D54;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0 54px;
  .playerBlock{
    display: flex;
    flex-direction: column;
    align-items: center;
    span{
      color:#ffffff;
      margin-top: 20px;
    }
  }
  .playerPlaceholder{
    border-radius: 5px;
    height: 450px;
  }
}
.vsContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .status{
    background: #2A3546;
    color:#ffffff;
  }
  .status,button{
    margin-top: 100px;
    padding: 0 25px;
    line-height: 47px;
    border-radius: 5px;
  }
}

.vs{
  width: 155px;
}

