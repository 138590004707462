.root {
  padding: 12px 12px 11px 12px;
  border-top: 1px solid #3f4f6d;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button{
    height: 44px;
  }
}

.button {
  background: #bb6d2a;
  border-radius: 8px;
  padding: 13px 14px;
  outline: none;
  border: none;
  text-align: center;
  color: #ffffff;
  width: 100%;
  cursor: pointer;
  margin-left: 8px;
  &:first-child{
    margin-left: 0;
  }
  &.fullscreen, &.leave {
    width: max-content;
    padding: 6px 8px;
  }
  &.qa{
    width: initial;
  }
  &.leave{
    img{
      filter: invert(99%) sepia(100%) saturate(2%) hue-rotate(41deg) brightness(106%) contrast(101%);
    }
  }
}
