.root{
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width: 100%;
  height: 100%;
  display:flex;
  align-items: center;
  justify-content: center;
  background: rgba(11, 18, 29, 0.8);
  z-index: 10000;
}
.modalContainer{
  background: #141D2D;
  border: 1px solid #2F3D54;
  border-radius: 8px;
}
.modalHeading{
  padding: 28px 23px;
  border-bottom: 1px solid #2F3D54;
  text-align: center;
  color: #ffffff;
  position:relative;
}
.modalContent{
  padding: 28px 61px 80px 61px;

}
.modalClose{
  position: absolute;
  top:0;
  bottom:0;
  right: 23px;
  margin: auto 0;
  cursor: pointer;
}