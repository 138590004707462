.root {
  background: #131B2B;
  border: 1px solid #34425B;
  border-radius: 10px;
  display:flex;
  align-items: center;
  justify-content:center;
  flex-direction: column;
  padding: 14px 12px 16px;
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  transition: all 0.25s ease-in-out;
  box-sizing: border-box;
  opacity: 0;
}
.heading{
  border-bottom: 1px solid #2F3D54;
  margin-bottom: 7px;
  padding: 0px 0px 16px 17px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  text-transform: capitalize;
  img{
    margin-right: 10px;
    height: 18px;
    width: initial;
  }
  span{
    color:#fff;
  }
}
.description{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #929EAD;
  margin: 0;
}

