.root{
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 400px;
  button{
    margin: 64px 0;
  }
  h1{
    margin: 0;
  }
  h3{
    text-align: center;
  }
}
.title{
  text-align: center;
  color: #929EAD;
  max-width: 360px;
  &:first-child{
    margin-top:  40px;
  }
  &:last-child {
    cursor: pointer;
  }
}