@import "src/assets/styles/_variables";

.root {
  position: relative;
  z-index: -1;
  td {
    padding: 3px 0px;
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9),
    &:nth-child(12),
    &:nth-child(15) {
      border-right: 1px solid #141d2d;
    }
  }
}
.players{
  display:flex;
  flex-direction: column;
  span{
    padding: 4px 20px 5px 20px;
    text-align: left;
  }
}
