.cards{
  display:flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;

  .card{
    margin-right: 30px;
    &:last-child{
      margin-right: 0;
    }
    &:hover{
      :global(.ability-tooltip){
        opacity: 1;
        bottom: 0;
        top: initial;
        transform: translatey(calc(100% + 10px));
      }
    }
  }
}
.title{
  text-align: center;
  color: #929EAD;
}
.root{
  h3{
    text-align: center;
  }
}