.root{

}
.suits{
  margin-bottom: 15px;
  display:flex;
}
.title{
  text-align: left;
  color: #929EAD;
  margin-bottom: 15px;
}