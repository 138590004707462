.root{
  & > button{
    margin: 0 auto;
    display: flex;
  }
}
.card{
  display: flex;
  column-gap: 15px;
  margin-bottom: 10px;
}
.title{
  text-align: left;
  color: #929EAD;
  margin-bottom: 15px;
}
.center{
  text-align: center;
}