.root{
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 10px;
  max-height:40vh;
  min-height: 35vh;
  overflow-y:scroll;
  padding: 0 20px;
  align-items: flex-start;
  &::-webkit-scrollbar {
    width: 0px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
}
.empty{
  place-self: center;
  width: 100%;
  text-align: center;
}