.root{
  margin: 0;
  appearance: none;
  cursor: pointer;
  padding: 5px 10px;
  background: #3f414d;
  color: #ececec;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  line-height: 20px;
  transition: all 0.1s;
  font-style: normal;
  font-weight: 400;
  &:first-child{
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;

  }
  &:last-child{
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
  }
  input {
    appearance: none;
    color: #ececec;
    font-family: "Inter", sans-serif;
    font-size: 13px;
    line-height: 20px;

    font-style: normal;
    font-weight: 400;
  }

  &.checked {
    background: #bb6d2a;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    text-shadow: 0 1px 0px #808080;
  }

  input::before {
    content: attr(label);
    text-align: center;
  }
}

