@import "src/assets/styles/_variables";

.root {
  z-index: 1;
  position: relative;
  width: 100%;
  height:100%;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  img {
    width: 100%;
  }

}
.mark{
  &.top, &.bottom{
    position: absolute;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    font-family: "Inter", sans-serif;
    color: #c12b2b;
    text-transform: uppercase;
    background-color: #fff;
    height: 50px;
    width: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
      width: 16px;
      filter: invert(18%) sepia(41%) saturate(2989%) hue-rotate(336deg) brightness(133%) contrast(101%);
    }
  }
  &.top{
    top: 5px;
    left: 5px;
  }
  &.bottom{
    bottom: 5px;
    right: 5px;
    display: flex;
    align-items: flex-end;
  }
  &.s, &.c{
    color: #3A4D68;
    img{
      filter: invert(28%) sepia(8%) saturate(2705%) hue-rotate(176deg) brightness(89%) contrast(83%);
    }
  }
}
