.root {
  color: #ffffff;
  padding: 0 36px;
  text-align: center;
  b,
  p {
    width: 100%;
    margin: 0;
  }
}
