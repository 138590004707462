@import "src/assets/styles/_variables";

.root {
  position: fixed;
  top:0;
  right:0;
  bottom: 0;
  left:0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 999;
  background-color: rgba(0,0,0, 0.25);

}
.result{
  width: 384px;
  height: 384px;
  background: #0B121D;
  box-shadow: 0px 0px 116px rgba(0, 0, 0, 0.25);
  padding: 16px;
  border-radius:100%;
  .inner{
    border-radius:100%;
    background: #0B121D;
    width:100%;
    height: 100%;
    box-shadow: 0px 0px 116px rgba(0, 0, 0, 0.25);
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid #BB6D2A;
    text-transform: capitalize;
    a{
      line-height: 1;
      font-size: 14px;
      color:#ffffff;
    }
  }
}
.resultLabel, .points{
  font-family: 'Harbour';
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 79px;
  text-align: center;
  color:#ffffff;
}

.round{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 58px;
  color:#ffffff
}
.points{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  margin-bottom: 10px;
}
:global(.loss){
  .inner{
    border-color:#F42121;
  }
  .points, .resultLabel{
    color: #F42121;
  }
}
:global(.win){
  .inner{
    color: #27AE60;
    border: 1px solid #27AE60;
  }
  .points, .resultLabel{
    color: #27AE60;
  }
}




