@import "src/assets/styles/_variables";

.root {
  position: absolute;
  top:0;
  right:0;
  bottom: 0;
  left:0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 999;
  background-color: rgba(0,0,0, 0.25);
  transition: opacity .25s ease-in-out;
}
.ribbon{
  width: 440px;
  height: 100px;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position:relative;
  span{
    font-family: 'Harbour';
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 40px;
    text-align: center;
    color: #BB6D2A;
  }
  &:before{
    content: "";
    box-shadow: 0 0 20px 0px #fff, 1px 0px 100px 20px #bb6d2a;
    z-index: -1;
    width: 70%;
    height: 55%;
    position: absolute;
  }
}
.round{
  font-family: 'Harbour';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #FFFFFF;
}




