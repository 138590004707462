.root{
  border-radius: 12px;
  overflow: hidden;
  height: 420px;
  width: 300px;
  display:flex;
  align-items: center;
  justify-content:center;
  color:#fff;
  font-family: "Harbour";
  font-style: normal;
  font-weight: 400;
  img{
    height: 100%;
  }
}