.root{
  min-width: 545px;
}
.players{
  color: #929EAD;
  display:flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.actions{
  margin-bottom: 15px;
}
.title{
  text-align: left;
  color: #929EAD;
  margin-bottom: 15px;
}
.submit{
  margin-left: auto;
  margin-right: 0;
  display: flex;
  margin-top: 30px;
}
.defaultBtn{
  display: flex;
  margin-top: 10px;
  margin-left: 0;
  margin-right: auto;
}