.root{
  display: grid;
  grid-template-columns: 150px 1fr;
  grid-column-gap: 65px;
  padding-right: 200px;
  a{
    display:block;
    border-radius: 8px;
    text-decoration: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 58px;
    color: #BB6D2A;
    background: #141D2D;
    height: max-content;
    text-align:center;
  }
}
.content{
  display:flex;
  flex-direction: column;
}

.abilities{
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 20px;
  width: 33vw;
  :global(.ability-rule){
    display:flex;
    align-items: center;
    flex-direction: row;
    margin:0;
    max-width: inherit;
    justify-content: initial;
    p, span{
      text-align: left;
    }
  }
  :global(.ability-rule-picture){
    margin-bottom: 0;
    margin-right: 20px;
  }
}
.heading{
  font-family: 'Harbour';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 68px;
  color: #BB6D2A;
}