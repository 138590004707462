@import "src/assets/styles/_variables";

.root {
  td {
    width: 70px;
    padding: 2px 0 3px;
    border-right: 1px solid #141d2d;
    border-top: 1px solid #141d2d;
    &:last-child {
      border-right: none;
    }
  }
}
