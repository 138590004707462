.root{
  max-width: 768px;
  margin: 0 auto;
  padding-bottom: 200px;
  span{
    color: #BB6D2A;
  }
  table{
    width: 100%;
    border-collapse: collapse;
    th{
      width: 50%;
    }
    th, td{
      padding: 15px 0 18px;
      font-family: 'Harbour';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #FFFFFF;
      text-align: center;
      border: 1px solid #BB6D2A;
    }
  }
}
.abilities{
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 20px;
  :global(.ability-rule){
    display:flex;
    align-items: center;
    flex-direction: row;
    margin:0;
    p, span{
      text-align: left;
    }
  }
  :global(.ability-rule-picture){
    margin-bottom: 0;
    margin-right: 20px;
  }
}