.root{
  border:1px solid #bb6d2a;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  line-height: 20px;
  border-radius: 5px;
  background-color: transparent;
  padding: 4px 10px;
  color: #fff;
}

