@import "../../../../../../../../assets/styles/_variables";

.root {
  border: 2px solid transparent;
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;
  filter: drop-shadow(0px 3.62158px 3.62158px rgba(0, 0, 0, 0.55));
  position: relative;
  width: 200px;
  height: 280px;
  @for $i from 2 through 18 {
    &:nth-child(#{$i}) {
      margin-left: -150px;
    }
  }
  img {
    width: 100%;
  }
  transition: all 0.25s ease-in-out;


  &.opponent {
    pointer-events: none;
  }
}

.inner{
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.front, .back{
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 10px;
}
.back{
  transform: rotateY(180deg);
}