.root {
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  position: relative;
  overflow: hidden;
}

:global(.opponent) {
  flex-direction: column-reverse;
  overflow: initial;
}
