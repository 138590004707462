.root {
  background: url(../../../../../../../../assets/svg/card-drop.svg);
  border: 1px solid #2f3d54;
  box-shadow: inset 0px 3px 71px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  width: 270px;
  height: 378px;
  background-size: 103%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.temp, .dealingImage{
  width: 200px;
  height: 280px !important;
  border-radius: 10px
}


