.root {
  display: flex;
  position: relative;
  margin-top: -140px;
  min-height: 280px;
  margin-bottom: 0;
  z-index: 2;
  transform: translateY(-300px);
  transition: all 0.5s ease-in-out;
  &.dealt{
    transform: translateY(0px);
  }
}
