.root{
  width:8px;
  height: 8px;
  background: #27AE60;
  position: absolute;
  z-index: 9;
  top: calc(100% - 9px);
  transition: all .5s ease-in-out;
  transform: rotate(-180deg);
  &:before{
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 8px;
    left: 1px;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 3px solid #27AE60;
  }
  &.opponent{
    top: 0px;
    background: #EB5757;
    transform: rotate(0);
    &:before{
      border-top: 3px solid #EB5757;
    }
  }
}