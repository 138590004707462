.root{
  display:flex;
  align-items: center;
  justify-content: space-between;
  height:100%;
  background-color: #161D2C;
  border-radius: 4px;
  overflow: hidden;
  padding-left: 24px;
}
.walletDisconnect{
  padding: 11px 12px;
  background-color: #161D2C;
  border-left:1px solid #3F4551;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  cursor: pointer;
}
.walletAddress{
  margin-left: 8px;
  background-color: #090F18;
  padding: 4px 8px;
  border-radius: 4px;
  color: #cdcdcd;
  font-size: 0.875rem;
  font-family: Inter,sans-serif;
  font-weight: 400;
  line-height: 1.43;
}
.currencyName{
  font-size: 12px;
  font-weight: 700;
  margin-left: 8px;
  color: #cdcdcd;
  font-family: Inter,sans-serif;
  line-height: 1.43;
}
.currencyAmount{
  color: #cdcdcd;
  font-size: 0.875rem;
  font-family: Inter,sans-serif;
  font-weight: 400;
  line-height: 1.43;
}
