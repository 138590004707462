.root{
  grid-area: header;
  background-color: #2F3D54;
  padding: 8px 56px;
  width:100%;
  box-sizing: border-box;
  color:#FFFFFF;
  background-image: url(https://www.pipcards.com/assets/top-bar-bg.png) !important;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Harbour';
}
.title{
  font-size: 24px;
}
.actions{
  display:flex;
  align-items: center;
  justify-content: space-between;
  height:100%;

  a{
    font-size: 16px;
    margin-right: 30px;
    color: #fff;
    text-decoration: none;
  }
  button{
    height:100%;
    font-family: 'Harbour';
    &:first-of-type{
      margin-right: 10px;
    }
  }
}


