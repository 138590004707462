.root {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: grid;
  grid-template-rows: 290px calc(100vh - 36px - 290px - 200px) 200px;
  grid-row-gap: 18px;
  grid-area: remaining-cards;
  height: 100%;
  padding: 0 34px 0 25px;
  width: 200px;
  border-right: 1px dashed #3f4f6d;
}
